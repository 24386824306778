import axios from "axios";

export class LoginAPI {
  async authenticateUser(username, password, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/login/`, {
        username, password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return { user: obj.data.user, state: obj.data.state };
    } catch (error) {
      const { response } = error;
      return { user: null, state: response.data.state };
    }
  }

  async requestUnlockAccount(username, email, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/unlock_account/`, {
        username, email
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return { state: obj.data.state, timeout: obj.data.timeout };
    } catch (error) {
      return { state: "invalid", timeout: 0 };
    }
  }

  async unlockUserAccount(username, email, token, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/unlock_account_confirm/`, {
        username, email, token
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return { state: obj.data.state };
    } catch (error) {
      return { state: "invalid_or_expired" };
    }
  }

  async requestPasswordReset(username, email, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/reset_password/`, {
        username, email
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return { state: obj.data.state, timeout: obj.data.timeout };
    } catch (error) {
      return { state: "invalid", timeout: 0 };
    }
  }

  async resetPasswordConfirm(email, token, password, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/reset_password_confirm/`, {
        username: email, email, token, password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return obj.data.state;
    } catch (error) {
      return "invalid_or_expired";
    }
  }

  async changePassword(currentPassword, newPassword, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/change_password/`, {
        old_password: currentPassword, new_password: newPassword
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return obj.data;
    } catch (error) {
      return error?.response?.data;
    }
  }

  async verifyOPT(otp, rememberDevice, csrfToken) {
    try {
      const obj = await axios.post(`/api/auth/verify_user_email/`, {
        otp, remember_device: rememberDevice
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': csrfToken
        }
      });
      return { user: obj.data.user, state: obj.data.state };
    } catch (error) {
      return { user: null, state: "invalid" };
    }
  }

  logout(csrfToken) {
    return axios.post("/api/auth/logout/", null, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFTOKEN': csrfToken
      }
    });
  }

  async sendOtp() {
    try {
      const { data } = await axios.get(`/api/auth/send_otp_user_email`);
      if (!data) {
        return false;
      }
      return data.state === "sent";
    } catch (error) {
      return false;
    }
  }

  async getCsrfToken() {
    try {
      const { data } = await axios.get(`/api/auth/set_csrf`);
      if (data.status === "denied") {
        this.logout();
      }
      return data.csrf_token;
    } catch (error) {
      this.logout();
    }
  }
}
