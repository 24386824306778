import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ReleaseNotesDialog from "components/dialogs/ReleaseNotesDialog";
import releaseNoteMarkdownFileAdmin from "assets/releaseNotes_admin.md";
import releaseNoteMarkdownFileClient from "assets/releaseNotes_client.md";

export default function VersionRelease({ isClient }) {
  const [openReleaseNotes, setOpenReleaseNotes] = useState(false);
  const [releaseNoteMarkdown, setMarkdown] = useState('');

  const hideNewReleasesIcon = () => {
    localStorage.setItem('hideNewReleasesIcon', 'true');
  };

  const setVersion = () => {
    localStorage.setItem('version', process.env.REACT_APP_VERSION);
  };

  useEffect(() => {
    fetch(isClient ? releaseNoteMarkdownFileClient : releaseNoteMarkdownFileAdmin)
      .then(response => response.text())
      .then(text => setMarkdown(text));
  }, []);

  useEffect(() => {
    if (localStorage.getItem('version') !== process.env.REACT_APP_VERSION) {
      localStorage.setItem('hideNewReleasesIcon', 'false');
    }
  }, []);

  return (
    <>
      <ReleaseNotesDialog
        open={openReleaseNotes}
        onClose={() => {
          setOpenReleaseNotes(false);
        }}
        releaseNotes={releaseNoteMarkdown}
      />
      <div className="header-version_span"
        onClick={() => {
          setOpenReleaseNotes(true);
          hideNewReleasesIcon();
          setVersion();
        }}>
        {process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : ""}
        {localStorage.getItem('hideNewReleasesIcon') === 'true'
          ? null
          : <NewReleasesIcon onClick={hideNewReleasesIcon}
            style={{ fontSize: "16px" }} />
        }
      </div>
    </>
  );
}

VersionRelease.propTypes = {
  isClient: PropTypes.bool
};
