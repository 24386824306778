export function slugify(objId, title) {
  title = title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  return `${objId}-${title}`;
}

export function getSlugID(slug) {
  return slug.split('-')[0];
}
