import { useState } from "react";

import NotificationAPI from "api/customers/NotificationAPI";

const useNotification = () => {
  const notificationAPI = new NotificationAPI();
  const [unread, setUnread] = useState();

  const addNotificationToLocalStorage = async (total) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      if (!user.notifications) {
        user.notifications = {
          total,
          count: 0
        };

        if (total === 0) {
          setUnread(0);
        } else {
          await notificationAPI.countUnread().then(res => {
            setUnread(res.data);
            user.notifications.count = res.data;
          });
        }
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        user.notifications.count = total - user.notifications.total + user.notifications.count;
        user.notifications.total = total;
        localStorage.setItem('user', JSON.stringify(user));
        setUnread(user.notifications.count);
      }
    }
  };

  const markAllAsRead = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.notifications) {
      setUnread(0);
      user.notifications.count = 0;
      localStorage.setItem('user', JSON.stringify(user));
    }
  };

  return { unread, addNotificationToLocalStorage, markAllAsRead };
};

export default useNotification;
