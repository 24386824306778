import React from "react";
import PropTypes from "prop-types";
import CommentIcon from '@mui/icons-material/Comment';
import { Avatar } from "@mui/material";
import { slugify } from "utils/slugify";
import { useNavigate } from "react-router-dom";

const NewCommentItem = ({ notification }) => {
  const navigate = useNavigate();
  const content = notification.content;
  const prefix = notification.unread ? "unread-" : "";

  return (
    <div className="notification-item-link" onClick={() => {
      const slug = slugify(content.issue_id, content.subject);
      navigate(`/issue/${slug}#${content.comment_id}`);
    }}>
      <Avatar sx={{ bgcolor: 'rgb(234 88 12)' }}>
        <CommentIcon />
      </Avatar>
      <div className="notification-wrapper">
        <div className={`${prefix}notification-content`}>
          <span className={`${prefix}notification-text`}>{content.created_by || "Someone"} </span>
          has commented on your issue
          <span className={`${prefix}notification-text`}> #{notification.content.issue_id}: {notification.content.subject}</span>
        </div>
        <div className="notification-item-wrapper">
          <div className={`${prefix}notification-time`}>{notification.created_at}</div>
        </div>
      </div>
    </div>
  );
};

NewCommentItem.propTypes = {
  notification: PropTypes.object
};

export default NewCommentItem;
