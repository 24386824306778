import React, { Suspense } from "react";
import UnsupportedDevice from "pages/errors/unsupportedDevice";
import PropTypes from "prop-types";
import SuspenseLoading from "pages/SuspenseLoading";

export default function GenericComponentUI({ namespace, platform, uiProps }) {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      {(platform === "desktop" && namespace.desktop)
        ? <namespace.desktop {...uiProps}/>
        : (platform === "mobile" && namespace.mobile)
          ? <namespace.mobile {...uiProps}/>
          : <UnsupportedDevice platform={platform}/>
      }
      {(!["desktop", "mobile"].includes(platform)) && <UnsupportedDevice platform={platform}/> }
    </Suspense>
  );
};

GenericComponentUI.propTypes = {
  namespace: PropTypes.object,
  platform: PropTypes.oneOf(["desktop", "mobile"]),
  uiProps: PropTypes.object
};
