import RestAPI from "../base";

export default class NotificationAPI extends RestAPI {
  url = "/api/sstnews/notifications/";

  list(page) {
    return this.get(`${this.url}?page=${page}`);
  }

  countUnread() {
    return this.get(`${this.url}unread`);
  }

  markAsRead(id) {
    return this.get(`${this.url}${id}/read`);
  }
}
