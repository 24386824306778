import axios from "axios";
import qs from "qs";
import MD5 from "crypto-js/md5";
import useAuth from "pages/AuthProvider";

const errorNoGivenLogin = "05c588b38ac378a6d24ed30225f3caaf";
export default class RestAPI {
  constructor() {
    const { user, logout } = useAuth();
    this.user = user;
    this.logout = logout;
  }

  _getCsrfToken() {
    if (this.user) {
      return this.user.csrfToken;
    }
    return "not logged";
  }

  get(url, data) {
    return this._request('get', url, data);
  }

  post(url, data) {
    return this._request('post', url, data);
  }

  patch(url, data) {
    return this._request('patch', url, data);
  }

  put(url, data) {
    return this._request('put', url, data);
  }

  delete(url, data) {
    return this._request('delete', url, data);
  }

  onRequestError (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { detail } = error.response.data;
      if (error.response.status === 403 && detail) {
        const detailHash = MD5(detail).toString();
        if (detailHash === errorNoGivenLogin) {
          // Save location to redirect the user after login
          window.location.pathname !== '/' && localStorage.setItem('redirect', window.location.pathname);
          localStorage.removeItem('user');
          window.location.href = '/login';
          return;
        }
      }
    }
    throw error;
  }

  async _request(method, url, data) {
    data = data || {};

    const response = axios.request({
      method,
      url,
      params: method === "get" ? data : {},
      data: method === "get" ? {} : data,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFTOKEN': this._getCsrfToken()
      },
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      }
    }).catch(this.onRequestError);
    return response;
  }
}
