import React, { useState, useMemo, useContext } from "react";
import Cookie from "js-cookie";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { LoginAPI } from "api/authentication";
import moment from 'moment-timezone';

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [csrfToken, setCsrfToken] = useLocalStorage("csrfToken", null);
  const navigate = useNavigate();
  Cookie.set("user_tz", moment.tz.guess(true), { sameSite: 'Lax' });
  // call this function when you want to authenticate the user
  const login = (data) => {
    setUser(data);
  };

  const redirectToHome = () => {
    setUser(null);
    window.localStorage.removeItem("redirect");
    navigate("/", { replace: true });
  };

  const logout = () => {
    const loginApi = new LoginAPI();

    loginApi.logout(user?.csrfToken).then(
      redirectToHome,
      redirectToHome
    ).catch(() => {
      redirectToHome();
    });
  };

  const value = useMemo(
    () => ({
      user, login, logout, csrfToken, setCsrfToken
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any
};

export default function useAuth() {
  return useContext(AuthContext);
};
