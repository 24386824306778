import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingContext = createContext();
export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const hideLoading = () => {
    setIsLoading(false);
  };

  const showLoading = () => {
    setIsLoading(true);
  };

  const value = useMemo(() => {
    return { hideLoading, showLoading };
  }, [isLoading]);

  return (
    <LoadingContext.Provider value={value}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 2000 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </LoadingContext.Provider>
  );
};

LoadingContextProvider.propTypes = {
  children: PropTypes.any,
  view: PropTypes.string
};

export default function useLoading() {
  return useContext(LoadingContext);
};
