import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import useAuth from "pages/AuthProvider";

export default function LoginRequired ({ children }) {
  const { user } = useAuth();
  if (!user) {
    // Save the location to redirect the user after login
    localStorage.setItem("redirect", window.location.pathname);
    return <Navigate to="/login" />;
  } else {
    localStorage.removeItem("redirect");
  }
  return children;
};

LoginRequired.propTypes = {
  children: PropTypes.any
};
