import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MoodBadIcon from '@mui/icons-material/MoodBad';
import OneColumn from "themes/one_column_theme/OneColumnTheme";

export default function UnsupportedDevice(props) {
  return (
    <OneColumn>
      <div className="profile-page">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0.5} columns={14}>
            <div style={{ margin: "auto", padding: "10%" }}>
              <h1 style={{ color: "red" }}><MoodBadIcon/> Sorry! This content can not be displayed on your device!</h1>
              <div style={{ padding: "10px" }}>
                <p>Device type: {props.platform || "Unknown"}</p>
                <p>Please contact the administrator to resolve this issue.</p>
              </div>
            </div>
          </Grid>
        </Box>
      </div>
    </OneColumn>
  );
}

UnsupportedDevice.propTypes = {
  platform: PropTypes.string
};
