import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import NotificationAPI from "api/customers/NotificationAPI";

import useOutsideClick from "utils/handleOutsideClick";
import useNotification from "utils/useNotification";

import Notifications from "./Notifications";

const NotificationPopup = ({ renderLink }) => {
  const notificationAPI = new NotificationAPI();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const { unread, addNotificationToLocalStorage, markAllAsRead } = useNotification();

  const isMobile = window.innerWidth < 768;
  const path = isMobile ? '/m/me/notifications' : '/me/notifications';

  const listNotifications = (e, p) => {
    notificationAPI.list(p).then(res => {
      setData(res.data);
      addNotificationToLocalStorage(res.data.count);
    });
  };

  const openDialog = () => {
    markAllAsRead();
    if (!window.location.pathname.startsWith(path)) {
      setIsOpen(true);
    }
  };
  const closeDialog = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(closeDialog);

  useEffect(() => {
    listNotifications(undefined, 1);
  }, []);

  return (
    <div ref={ref}>
      {renderLink(({ onClick: isOpen ? closeDialog : openDialog }), unread)}
      {isOpen &&
      <div className="notification-dialog">
        <div className="notifications-header">
      Notifications
        </div>
        <Notifications items={data?.results || []}/>
        {data.results.length > 0 && <Link to={path} className="notifications-footer-link" onClick={closeDialog}>
          <div className="notifications-footer-wrapper">
            <svg className="notifications-footer-svg" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path></svg>
        View all
          </div>
        </Link>}
      </div>}
    </div>
  );
};

NotificationPopup.propTypes = {
  renderLink: PropTypes.func.isRequired
};

export default NotificationPopup;
