import { React, useEffect } from "react";
import PropTypes from "prop-types";

import LoginForm from "./login_form/LoginForm";

import "./login.css";
import OneColumn from "themes/one_column_theme/OneColumnTheme";
import { useLocation, useNavigate } from "react-router-dom";

export default function Login({ pageTitle }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 1024;
  // Auto navigate version desktop to version mobile
  useEffect(() => {
    if (isMobile && !location.pathname.includes("/m")) {
      navigate(`/m${location.pathname}`);
    }
  }, [location.pathname, isMobile]);
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <OneColumn>
      <LoginForm />
    </OneColumn>
  );
}

Login.propTypes = {
  pageTitle: PropTypes.string
};
