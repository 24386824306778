import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <p>
          &copy;Copyright 1998-{new Date().getFullYear()} Microchip Technology Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
