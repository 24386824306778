import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Markdown from "react-markdown";

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import BaseDialog from "components/dialogs/BaseDialog";
import releasePicture from "assets/images/releaseNotes.png";

const ReleaseNotesDialog = ({ open, onClose, releaseNotes }) => {
  const [selectedVersion, setSelectedVersion] = useState();
  const [versions, setVersions] = useState([]);
  const [value, setValue] = useState("");

  const filterVersion = (version) => {
    const pattern = new RegExp(
      `## Release Notes - Version ${version}[^]*?(?=## Release Notes - Version|$)`,
      "g"
    );
    const matches = releaseNotes.match(pattern);
    const desiredText = matches && matches.length > 0 ? matches[0] : "";
    setSelectedVersion(desiredText.substring(desiredText.indexOf("\n") + 1));
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    filterVersion(event.target.value);
  };

  useEffect(() => {
    const pattern = /## Release Notes - Version (\d+\.\d+\.\d+)/g;
    const matches = releaseNotes.match(pattern);
    const versions = matches ? matches.map((match) => match.replace("## Release Notes - Version ", "")) : [];
    setVersions(versions);
    setValue(versions[0]);
    filterVersion(versions[0]);
  }, [releaseNotes]);

  return (
    <BaseDialog
      isOpened={open}
      onClosed={onClose}
      title="Release Notes"
      maxWidth="md"
      fullWidth
    >
      {
        <>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={releasePicture} alt="Release Notes" style={{ width: "100%", height: "auto", objectFit: "contain" }} />
          </div>
          <div style={{ lineHeight: '1.6', width: "100%" }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flexStart',
                alignItems: 'center'
              }}>
              <h2>Release Notes - Version </h2>
              <FormControl
                size="small"
                sx={{
                  m: 1,
                  width: '25ch'
                }}>
                <Select
                  value={value}
                  onChange={handleChange}
                >
                  {versions.map((version) => {
                    return <MenuItem key={version} value={version}>{version}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Markdown className="markdown-content">
              {selectedVersion}
            </Markdown>
          </div>
        </>
      }
    </BaseDialog>
  );
};

ReleaseNotesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  releaseNotes: PropTypes.string
};

export default ReleaseNotesDialog;
