import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import useAuth from "pages/AuthProvider";
import useRenderProvider from "pages/PageRenderProvider";

import YesNoDialog from "components/dialogs/YesNoDialog";
import "./menuButton.css";

export default function MenuButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpen] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, logout } = useAuth();
  return (
    <div className="menu-button-container">
      <YesNoDialog
        state={openDialog}
        handleNo={() => {
          setOpen(false);
        }}
        content={"Are you sure you want to logout?"}
        handleYes={
          () => {
            logout();
            navigate("/");
            setOpen(false);
          }
        }
      />
      <Button
        className="basic-menu-btn"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<AccountCircleIcon />}
      >
        {user.first_name} {user.last_name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {useRenderProvider() === "client" && (
          <MenuItem className="menu-item" onClick={() => navigate("/me")}>
            <ListItemIcon>
              <ManageAccountsIcon sx={{ color: "white" }} />
            </ListItemIcon>
            My account
          </MenuItem>
        )}

        <MenuItem className="menu-item" onClick={() => { setOpen(true); }}>
          <ListItemIcon>
            <LogoutIcon sx={{ color: "white" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
