import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Avatar } from "@mui/material";

const NewVersionItem = ({ notification }) => {
  const navigate = useNavigate();
  const content = notification.content;
  const prefix = notification.unread ? "unread-" : "";

  return (
    <div className="notification-item-link" onClick={() => {
      navigate(`/product/${content.ip_id}-${content.ip_name}/version/${content.version_name}`);
    }}>
      <Avatar sx={{ bgcolor: 'rgb(14 165 233)' }}>
        <FiberNewIcon />
      </Avatar>
      <div className="notification-wrapper">
        <div className={`${prefix}notification-content`}>
          Version
          <span className={`${prefix}notification-text`}> {content.version_name} </span>
          of
          <span className={`${prefix}notification-text`}> {content.ip_name} </span>
          is now available!
        </div>
        <div className="notification-item-wrapper">
          <div className={`${prefix}notification-time`}>{notification.created_at}</div>
        </div>
      </div>
    </div>
  );
};

NewVersionItem.propTypes = {
  notification: PropTypes.object
};

export default NewVersionItem;
