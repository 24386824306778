import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationPopup from "components/notification/NotificationPopup";

const NotificationButton = () => {
  return <NotificationPopup
    renderLink={(linkProps, unread) => (
      <div {...linkProps} className="notification-dropdown-btn">
        <NotificationsIcon fontSize="inherit"/>
        {unread > 0 && unread < 100 && <span className="unread-span">{unread}</span>}
        {unread >= 100 && <span className="unread-span">99+</span>}
      </div>
    )} />;
};

export default NotificationButton;
