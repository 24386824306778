import RestAPI from "../base";

export default class CustomerAPI extends RestAPI {
  getCustomerLicense() {
    return this.get("/api/cma/customer-licenses/");
  }

  getCustomerProducts() {
    return this.get("/api/cma/customer-products/");
  }

  retrieve() {
    return this.get("/api/cma/customer/");
  }

  getUserProfile() {
    return this.get(`/api/cma/profile/`);
  }

  getRequestsDownload() {
    return this.get("/api/cma/requests");
  }

  getRequestsDownloadById(requestId) {
    return this.get(`/api/cma/requests/?ip_version=${requestId}`);
  }

  updateUserProfile(profileObj) {
    const { id, ...userProfile } = profileObj;
    return this.put("/api/cma/profile/me/", userProfile);
  }

  updateUserInfo(userObj) {
    const { id, ...userInfo } = userObj;
    return this.put("/api/cma/customer/me/", userInfo);
  }

  getMRDResourceID(versionId) {
    return this.get(`/api/cma/requests/download/mrd/${versionId}/`);
  }

  requestDownloadDesignkit(versionId, encryption = -1) {
    return this.get(`/api/cma/requests/download/designkit/${versionId}/`, { encryption });
  }

  getTANResourceID(versionId) {
    return this.get(`/api/cma/requests/download/tan/${versionId}/`);
  }

  requestDocumentDownload(versionId, docType) {
    switch (docType.toLowerCase()) {
    case "tan":
      return this.getTANResourceID(versionId);

    case "mrd":
      return this.getMRDResourceID(versionId);
    }
  }

  downloadFile(resourceId) {
    const link = document.createElement('a');
    link.href = `/api/cma/downloadfile/${resourceId}/`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getDownloadInfo(resourceId, createTask) {
    return this.get(`/api/cma/downloadinfo/${resourceId}/`, { create_task: createTask });
  }

  getInfoDownloadFromAdmin(resourceId) {
    return this.get(`/api/cma/download_info_from_admin/${resourceId}/`);
  }

  cancelDownloadRequest(requestId) {
    return this.delete(`/api/cma/requests/${requestId}/`);
  }
}
