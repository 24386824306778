import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import DownloadIcon from "@mui/icons-material/Download";
import BugReportIcon from "@mui/icons-material/BugReport";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationButton from "./notification-button/NotificationButton";

const routes = [
  { path: "/m", value: 0, label: "Home", icon: <HomeIcon /> },
  { path: "/m/products", value: 1, label: "Products", icon: <CategoryIcon /> },
  { path: "/m/downloads", value: 2, label: "Downloads", icon: <DownloadIcon /> },
  { path: "/m/issues", value: 3, label: "Issues & Requests", icon: <BugReportIcon /> }
];

export default function HeaderMobile({ user, navigate, setOpen }) {
  const path = window.location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [value, setValue] = useState("");
  useEffect(() => {
    const route = routes.find(route => route.path === path);
    if (route) {
      setValue(route.value);
    }
  }, [path]);
  return (
    <>
      <div>
        {(!user && (path !== "/m/login" && path !== "/m/register")) && (
          <>
            <Button sx={{ padding: "0px", minWidth: "18px", fontSize: "12px" }}
              onClick={() => { navigate("/m/login"); }}>
              Sign in
            </Button>
            <span style={{ padding: "0px 5px" }}>|</span>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/m/register");
              } }
              sx={{ padding: "2px 5px", minWidth: "18px", fontSize: "12px", marginRight: "6px", backgroundColor: "#0585C8", color: "#fff" }} >
                Sign up
            </Button>
          </>

        )}
        {(!user && path === "/m/login") && (
          <Button
            variant="contained"
            onClick={() => {
              navigate("/m/register");
            }}
            sx={{ fontSize: "12px", marginRight: "6px", backgroundColor: "#0585C8", color: "#fff" }} >
                Sign up
          </Button>
        )}
        {(!user && path === "/m/register") && (
          <Button
            variant="contained"
            onClick={() => {
              navigate("/m/login");
            }}
            sx={{ fontSize: "12px", marginRight: "6px", backgroundColor: "#0585C8", color: "#fff" }} >
                Sign in
          </Button>
        )}
        {user && (
          <div style={{ display: "flex" }}>
            <NotificationButton />
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ padding: "0px", minWidth: "48px" }}
            >
              <AccountCircleIcon />
            </Button>
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem className="menu-item" onClick={() => navigate("/m/me")}>
            <ListItemIcon>
              <ManageAccountsIcon sx={{ color: "white" }} />
            </ListItemIcon>
            My account
          </MenuItem>
          <MenuItem className="menu-item" onClick={() => { setOpen(true); }}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: "white" }} />
            </ListItemIcon>
          Logout
          </MenuItem>
        </Menu>
      </div>
      {user && (
        <Box sx={{ width: '100%' }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue === 0) {
                navigate("/m");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
              if (newValue === 1) {
                navigate("/m/products");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
              if (newValue === 2) {
                navigate("/m/downloads");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
              if (newValue === 3) {
                navigate("/m/issues");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          >
            <BottomNavigationAction label="Home" icon={<HomeIcon />}/>
            <BottomNavigationAction label="Products" icon={<CategoryIcon />} />
            <BottomNavigationAction label="Downloads" icon={<DownloadIcon />} />
            <BottomNavigationAction label="Issues & Requests" icon={<BugReportIcon />} />
          </BottomNavigation>
        </Box>
      )}
    </>
  );
};

HeaderMobile.propTypes = {
  user: PropTypes.object,
  navigate: PropTypes.func,
  setOpen: PropTypes.func
};
