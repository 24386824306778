import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import UpdateIcon from '@mui/icons-material/Update';
import { Avatar } from "@mui/material";
import { slugify } from "utils/slugify";

const IssueChangeItem = ({ notification }) => {
  const navigate = useNavigate();
  const content = notification.content;
  const prefix = notification.unread ? "unread-" : "";

  return (
    <div className="notification-item-link" onClick={() => {
      const slug = slugify(content.id, content.subject);
      navigate(`/issue/${slug}`);
    }}>
      <Avatar sx={{ bgcolor: 'rgb(234 179 8)' }}>
        <UpdateIcon />
      </Avatar>
      <div className="notification-wrapper">
        <div className={`${prefix}notification-content`}>
        The status of the issue
          <span className={`${prefix}notification-text`}> #{content.id}: {content.subject} </span>
          has been updated to
          <span className={`${prefix}notification-text`}> {content.status}</span>
        </div>
        <div className="notification-item-wrapper">
          <div className={`${prefix}notification-time`}>{notification.created_at}</div>
        </div>
      </div>
    </div>
  );
};

IssueChangeItem.propTypes = {
  notification: PropTypes.object
};

export default IssueChangeItem;
