import React from "react";
import PropTypes from "prop-types";

import NotificationAPI from "api/customers/NotificationAPI";

import ApproveItem from "./ApproveItem";
import RejectItem from "./RejectItem";
import IssueChangeItem from "./IssueChangeItem";
import NewCommentItem from "./NewCommentItem";
import NewVersionItem from "./NewVersionItem";
import LicenseItem from "./LicenseItem";

const NotificationItem = ({ notification }) => {
  const notificationAPI = new NotificationAPI();

  const markAsRead = () => {
    if (notification.unread) {
      notificationAPI.markAsRead(notification.id);
    }
  };
  const items = {
    approvedownload: <ApproveItem notification={notification}/>,
    rejectdownload: <RejectItem notification={notification} />,
    issuechange: <IssueChangeItem notification={notification} />,
    newcomment: <NewCommentItem notification={notification} />,
    newversion: <NewVersionItem notification={notification} />,
    license: <LicenseItem notification={notification} />
  };

  return <div onClick={markAsRead}>{items[notification.category]}</div>;
};

NotificationItem.propTypes = {
  notification: PropTypes.object
};

export default NotificationItem;
