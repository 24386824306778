import { React, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/SST-Logo.png";
import MenuButton from "./menu-button/MenuButton";
import "./header.css";
import useAuth from "pages/AuthProvider";
import useRenderProvider from "pages/PageRenderProvider";
import YesNoDialog from "components/dialogs/YesNoDialog";
import NotificationButton from "./notification-button/NotificationButton";
import HeaderMobile from "./HeaderMobile";
import VersionRelease from "./version-release/versionRelease";

export default function Header({ index }) {
  const [tabIndex, setTabIndex] = useState(index);
  const { user, logout } = useAuth();
  const workSite = useRenderProvider();
  const [openDialog, setOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 1023;

  return (
    <header>
      <YesNoDialog
        state={openDialog}
        handleNo={() => {
          setOpen(false);
        }}
        content={"Are you sure you want to logout?"}
        handleYes={() => {
          logout();
          navigate("/m");
          setOpen(false);
        }}
      />
      <nav className="header-nav">
        <div className="header-container">
          <div className="header-link">
            <Link to={isMobile ? "/m" : "/"} className="header-link">
              <img src={logo} className="header-link_img" alt="IPMS Logo" />
              <span className="header-link_span">
              IP Management System
              </span>
            </Link>
            {
              !isMobile && (
                <VersionRelease isClient={workSite === "client"} />
              )
            }
          </div>
          <div className="header-auth">
            {!user
              ? (
                <>
                  {workSite === "client" && (
                    <>
                      <Link to="/login" className="header-auth_login-btn">Sign in</Link>
                      <Link to="/register" className="header-auth_register-btn">Sign up</Link>
                    </>
                  )}
                </>
              )
              : (
                <>
                  <NotificationButton />
                  <MenuButton/>
                </>
              )}
          </div>
          <div className="header-wrapper">
            {user && (
              <ul className="header-wrapper_ul">
                <li>
                  <Link
                    to="/"
                    className={
                      tabIndex === 0
                        ? "header_ul_link link-focus"
                        : "header_ul_link"
                    }
                    onClick={() => setTabIndex(0)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/products"
                    className={
                      tabIndex === 1
                        ? "header_ul_link link-focus"
                        : "header_ul_link"
                    }
                    onClick={() => setTabIndex(1)}
                  >
                    Products
                  </Link>
                </li>
                <li>
                  <Link
                    to="/downloads"
                    className={
                      tabIndex === 2
                        ? "header_ul_link link-focus"
                        : "header_ul_link"
                    }
                    onClick={() => setTabIndex(2)}
                  >Downloads</Link>
                </li>
                <li>
                  <Link
                    to="/issues"
                    className={
                      tabIndex === 3
                        ? "header_ul_link link-focus"
                        : "header_ul_link"
                    }
                    onClick={() => setTabIndex(3)}
                  >
                    Issues & Requests
                  </Link>
                </li>
              </ul>
            )}
          </div>
          {isMobile && <HeaderMobile
            user={user}
            navigate={navigate}
            setOpen={setOpen}
          />}
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  index: PropTypes.any
};
