import { React, lazy, useEffect } from "react";
import PropTypes from 'prop-types';
import GenericComponentUI from "pages/generic";
import UnsupportedDevice from "pages/errors/unsupportedDevice";
import useAuth from "pages/AuthProvider";

const UI = {
  desktop: lazy(() => import(
    `pages/clients/home/Home.ui.desktop`
  ).catch(() => ({ default: () => <UnsupportedDevice platform="desktop"/> }))),

  mobile: lazy(() => import(
    `pages/clients/home/Home.ui.mobile`
  ).catch(() => ({ default: () => <UnsupportedDevice platform="mobile"/> })))
};

export default function Home({ platform }) {
  const { user } = useAuth();

  useEffect(() => {
    document.title = user ? `IPMS - Home` : "SST - IP Management System";
  }, [user]);

  return (
    <GenericComponentUI namespace={UI} platform={platform} />
  );
}

Home.propTypes = {
  platform: PropTypes.oneOf(["desktop", "mobile"])
};
