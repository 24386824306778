import React, { useContext } from "react";
import PropTypes from "prop-types";

export const RenderContext = React.createContext();

export const PageRenderProvider = ({ view, children }) => {
  return (
    <RenderContext.Provider value={view}>
      {children}
    </RenderContext.Provider>
  );
};

PageRenderProvider.propTypes = {
  children: PropTypes.any,
  view: PropTypes.string
};

export default function useRenderProvider() {
  return useContext(RenderContext);
};
