import React from "react";
import PropTypes from "prop-types";

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import NotificationItem from "./items/NotificationItem";

import "assets/css/notification.css";

const Notifications = ({ items }) => {
  return (
    <>
      { items.length > 0
        ? items.map((item, index) => (
          <NotificationItem key={index} notification={item}/>
        ))
        : <div className="notifications-body">
          <NotificationsNoneIcon fontSize="inherit"/>
          <p className="no-notifications_p">You have no notifications</p>
        </div> }
    </>
  );
};

Notifications.propTypes = {
  items: PropTypes.array
};

export default Notifications;
