import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import "./loginForm.css";
import useAuth from "pages/AuthProvider";
import { LoginAPI } from "api/authentication";
import useRenderProvider from "pages/PageRenderProvider";
import OTPInput from "react-otp-input";
const OTP_SIZE = 8;
const OPT_TIMEOUT = 45;

export default function LoginForm(props) {
  const workSite = useRenderProvider();
  const landingPage = workSite === "admin" ? "/admin" : "/products";
  const [showLoading, setLoading] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [otpPassword, setOtpPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [password, setPassword] = useState("");
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const { login, user } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [otpTimeout, setOtpTimeout] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const loginApi = new LoginAPI();
  const isMobile = window.innerWidth < 1024;
  useEffect(() => {
    if (otpTimeout !== null) {
      if (otpTimeout < 0) {
        setOtpTimeout(null);
      }
    }
  }, [otpTimeout]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOtpTimeout(e => e ? e - 1 : null);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onClickLogin = async () => {
    setLoading(true);
    setLoginErrorMsg("");
    const csrfToken = await loginApi.getCsrfToken();
    if (!csrfToken) {
      setLoading(false);
      return setLoginErrorMsg("* Sorry, we were ran in to a problem. Please reload your browser to fix the issue!");
    }

    const { user, state } = await loginApi.authenticateUser(loginId, password, csrfToken);

    if (state === "locked") {
      setLoading(false);
      return setLoginErrorMsg("locked");
    }

    if (state === "invalid_input" || state === "invalid" || !user) {
      setLoading(false);
      return setLoginErrorMsg("* Incorrect username or password, please check your input again.");
    }

    if (state === "unverified_email") {
      const sentOpt = await loginApi.sendOtp();
      if (!sentOpt) {
        setLoading(false);
        return setLoginErrorMsg("* Sorry, we were ran in to a problem. Please reload your browser to fix the issue!");
      }
      setOtpTimeout(OPT_TIMEOUT);
      setLoading(false);
      setUserInfo(user);
    }

    setLoading(false);
    if (state === "logged_in") {
      login(user);
    }
  };

  useEffect(() => {
    if (otpPassword.length === OTP_SIZE) {
      onClickVerifyOTP();
    }
  }, [otpPassword]);

  const onResendOPT = async () => {
    setLoading(true);
    setLoginErrorMsg("");
    const sentOpt = await loginApi.sendOtp();
    if (!sentOpt) {
      setLoginErrorMsg("* Sorry, we were ran in to a problem. Please reload your browser to fix the issue!");
    }
    setOtpTimeout(OPT_TIMEOUT);
    setOtpPassword("");
    setLoading(false);
  };

  const onClickVerifyOTP = async () => {
    setLoading(true);
    const { user, state } = await loginApi.verifyOPT(
      otpPassword, rememberMe, userInfo.csrfToken);

    setLoading(false);
    if (state === "invalid_or_expired" || state === "invalid_input" || !user) {
      return setLoginErrorMsg("* Sorry! Your OTP password is invalid or expired!");
    }

    if (state === "logged_in") {
      setUserInfo(null);
      login(user);
    }
  };

  if (user) {
    // Get the redirect location
    const redirect = localStorage.getItem("redirect");
    return <Navigate to={redirect || landingPage} />;
  }
  const handleChange = (newValue) => {
    setOtpPassword(newValue);
  };
  return (
    <section>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showLoading} ></Backdrop>
      <div className="login-form">
        <div className="login-form-container">
          <div className="login-form-wrapper">
            <h1 className="login-form_title">Sign In</h1>
            <form onSubmit={(e) => e.preventDefault()}>
              {loginErrorMsg !== "" && loginErrorMsg === "locked"
                ? <p style={{ fontSize: "12px", color: "red" }}>
                 * Sorry, you account has been locked due to many login attempts. Please <Link to='/unlockaccount'>click here</Link> to unlock your account.
                </p>
                : ""
              }
              {loginErrorMsg !== "" && loginErrorMsg !== "locked"
                ? <p style={{ fontSize: "12px", color: "red" }}>{loginErrorMsg}</p>
                : ""
              }
              {userInfo !== null && (
                <div>
                  <div>
                    <label htmlFor="otppassword" className="login-form_label">
                    A verification code has been sent to your email. Please enter this code to confirm your identity.
                    </label>
                    <OTPInput
                      containerStyle={{ width: "100%", display: "flex", justifyContent: "space-around" }}
                      inputStyle={{ width: "10%", height: "54px", margin: "0 2px", borderRadius: "4px", border: "1px solid #0284C7", textAlign: "center", fontSize: "20px" }}
                      value={otpPassword}
                      onChange={handleChange}
                      numInputs={OTP_SIZE}
                      onComplete={onClickVerifyOTP}
                      renderSeparator={<span></span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <Button sx={{
                    marginTop: "16px",
                    width: "100%",
                    color: "white",
                    backgroundColor: "rgb(2 132 199)",
                    '&:hover': {
                      backgroundColor: "white",
                      color: "rgb(2 132 199)",
                      outline: "1px solid rgb(2 132 199)",
                      boxShadow: "none"
                    }
                  }}
                  variant="contained"
                  disabled={!otpPassword }
                  onClick={() => onClickVerifyOTP()}
                  >Verify</Button>

                  {otpTimeout == null && (
                    <p className="login-form-dont_have_account">
                      Not yet received OTP?
                      <Link onClick={(e) => onResendOPT()} className="login-form-signup-btn">
                        Click here to resend OTP
                      </Link>
                    </p>
                  )}

                  {otpTimeout !== null && (
                    <p className="login-form-dont_have_account">
                      Your OTP will be expired in {otpTimeout} seconds
                    </p>
                  )}

                  <div className="login-form-box_label">
                    <div className="login-form-box-container">
                      <div className="login-form-box_wrapper">
                        <input id="remember"
                          checked={rememberMe}
                          onChange={(e) => {
                            setRememberMe(!rememberMe);
                          }}
                          aria-describedby="remember" type="checkbox"
                          className="login-form-box_input"/>
                      </div>
                      <div className="login-form-box_label">
                        <label htmlFor="remember" className="login-form-remember-me">
                          Remember me for next 7 days
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {userInfo === null && (
                <div>
                  <div>
                    <TextField required size="small"
                      fullWidth={true}
                      sx={{ marginBottom: 3, marginTop: 5 }}
                      value={loginId}
                      label={ workSite === "client" ? "Email" : "Username" } placeholder={ workSite === "client" ? "Your email..." : "Your username..."}
                      onChange={(e) => setLoginId(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key.toLowerCase() === "enter") {
                          if (loginId && password) {
                            onClickLogin();
                          }
                        }
                      }}
                      InputLabelProps={{ shrink: true }}/>
                  </div>
                  <div>
                    <TextField required size="small"
                      value={password}
                      fullWidth={true}
                      sx={{ marginBottom: 1 }}
                      type={showPassword ? "text" : "password"}
                      label={"Password"} placeholder={"Your password..."}
                      onChange={(e) => setPassword(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      onKeyUp={(e) => {
                        if (e.key.toLowerCase() === "enter") {
                          if (loginId && password) {
                            onClickLogin();
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton style={{ marginRight: "-10px", color: "#6c5d5d" }}
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}>
                              <VisibilityIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}/>
                  </div>

                  <Button disabled={!password || !loginId }
                    onClick={() => onClickLogin()}
                    sx={{
                      marginTop: "5px",
                      color: "white",
                      backgroundColor: "rgb(2 132 199)",
                      width: "100%",
                      '&:hover': {
                        backgroundColor: "white",
                        color: "rgb(2 132 199)",
                        outline: "1px solid rgb(2 132 199)",
                        boxShadow: "none"
                      }
                    }} variant="contained"
                  >Sign in</Button>

                  {workSite === "client" && (
                    <>
                      <div className="login-form-dont_have_account">
                        Forgot your password?
                        <Link to={isMobile ? "/m/resetpassword" : "/resetpassword"} className="login-form-signup-btn">
                          Click here to reset password.
                        </Link>
                      </div>
                      <p className="login-form-dont_have_account">
                        Don’t have an account yet?
                        <Link to={isMobile ? "/m/register" : "/register"} className="login-form-signup-btn">
                          Sign up
                        </Link>
                      </p>
                    </>
                  )}
                </div>
              )}

            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

LoginForm.propTypes = {
  setUser: PropTypes.any
};
