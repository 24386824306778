import { React, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "pages/AuthProvider";
import { PageRenderProvider } from "pages/PageRenderProvider";
import { LoadingContextProvider } from "pages/LoadingScreenProvider";

import "./App.css";
import SuspenseLoading from "pages/SuspenseLoading";
import Login from "pages/login/Login";
import Home from "pages/clients/home/Home";
import LoginRequired from "pages/LoginRequired";
import Products from "pages/clients/products/Products";
import lazyImport from "utils/loader";
const prefetchPages = [];
const prefetchList = [];

const ResetPassword = lazyImport(() => import("pages/login/ResetPassword"));
const SetPassword = lazyImport(() => import("pages/login/SetPassword"));
const UnlockAccount = lazyImport(() => import("pages/login/UnlockAccount"));
const ProductDetails = lazyImport(() => import("pages/clients/product_details/ProductDetails"), prefetchPages);
const DownloadRequests = lazyImport(() => import("pages/clients/download_requests/DownloadRequests"), prefetchPages);
const MyIssues = lazyImport(() => import("pages/clients/my_issues/MyIssues"), prefetchList);
const Signup = lazyImport(() => import("pages/signup/Signup"), prefetchPages);
const ProfileRouter = lazyImport(() => import("pages/clients/profile/profileRouter"));
const IssueDetails = lazyImport(() => import("pages/clients/issue_details/IssueDetails"));
const ReportIssue = lazyImport(() => import("pages/clients/report_issue/ReportIssue"));
const RequestIp = lazyImport(() => import("pages/clients/request_ip/RequestIp"));
const ErrorPageRouter = lazyImport(() => import("pages/errors/router"));
const DownloadFileNotReady = lazyImport(() => import("pages/clients/download_requests/downloadFileNotReady"));
const PageNotFound = lazyImport(() => import("pages/errors/pageNotFound"), prefetchList);

function ClientRouter() {
  return <Suspense fallback={<SuspenseLoading />}>
    <Routes>
      <Route path="/errors/*"
        element={<ErrorPageRouter />}
      ></Route>

      <Route path="/products"
        element={<LoginRequired>
          <Products
            platform="desktop"
            pageTitle="IPMS - Products" />
        </LoginRequired>}></Route>

      <Route path="/m/products"
        element={<LoginRequired>
          <Products
            platform="mobile"
            pageTitle="IPMS - Products" />
        </LoginRequired>}></Route>

      <Route path="/downloads"
        element={<LoginRequired>
          <DownloadRequests pageTitle="IPMS - Download Requests" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/downloads"
        element={<LoginRequired>
          <DownloadRequests pageTitle="IPMS - Download Requests" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/downloads/:uuid"
        element={<LoginRequired>
          <DownloadFileNotReady platform="desktop" pageTitle="IPMS - Preparing Download Data" />
        </LoginRequired>}></Route>

      <Route path="/m/downloads/:uuid"
        element={<LoginRequired>
          <DownloadFileNotReady platform="mobile" pageTitle="IPMS - Preparing Download Data" />
        </LoginRequired>}></Route>

      <Route path="/issues"
        element={<LoginRequired>
          <MyIssues pageTitle="IPMS - My Issues" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/issues"
        element={<LoginRequired>
          <MyIssues pageTitle="IPMS - My Issues" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/product/:productSlug/version/:version"
        element={<LoginRequired>
          <ProductDetails pageTitle="IPMS - Product Specification" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/product/:productSlug/version/:version"
        element={<LoginRequired>
          <ProductDetails pageTitle="IPMS - Product Specification" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/product/:productSlug/"
        element={<LoginRequired>
          <ProductDetails pageTitle="IPMS - Product Specification" platform="desktop" />
        </LoginRequired>}></Route>
      <Route path="/m/product/:productSlug/"
        element={<LoginRequired>
          <ProductDetails pageTitle="IPMS - Product Specification" platform="mobile"/>
        </LoginRequired>}></Route>

      <Route path="/issue/:slug"
        element={<LoginRequired>
          <IssueDetails pageTitle="IPMS - Issue Details" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/issue/:slug"
        element={<LoginRequired>
          <IssueDetails pageTitle="IPMS - Issue Details" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/report-issue"
        element={<LoginRequired>
          <ReportIssue pageTitle="IPMS - Report Issue" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/report-issue"
        element={<LoginRequired>
          <ReportIssue pageTitle="IPMS - Report Issue" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/report-issue/product/:versionID"
        element={<LoginRequired>
          <ReportIssue pageTitle="IPMS - Report Issue" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/report-issue/product/:versionID"
        element={<LoginRequired>
          <ReportIssue pageTitle="IPMS - Report Issue" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/request-ip"
        element={<LoginRequired>
          <RequestIp pageTitle="IPMS - Request New IP" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/request-ip"
        element={<LoginRequired>
          <RequestIp pageTitle="IPMS - Request New IP" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/me/*"
        element={<LoginRequired>
          <ProfileRouter pageTitle="IPMS - User Profile" platform="desktop" />
        </LoginRequired>}></Route>

      <Route path="/m/me/*"
        element={<LoginRequired>
          <ProfileRouter pageTitle="IPMS - User Profile" platform="mobile" />
        </LoginRequired>}></Route>

      <Route path="/login"
        element={<Login pageTitle="IPMS - Login" />}></Route>

      <Route path="/m/login"
        element={<Login pageTitle="IPMS - Login" />}></Route>

      <Route path="/resetpassword"
        element={<ResetPassword pageTitle="IPMS - Reset Password" />}></Route>

      <Route path="/m/resetpassword"
        element={<ResetPassword pageTitle="IPMS - Reset Password" />}></Route>

      <Route path="/unlockaccount"
        element={<UnlockAccount type={"request"}
          pageTitle="IPMS - Request account unlock" />}></Route>

      <Route path="/m/unlockaccount"
        element={<UnlockAccount type={"request"}
          pageTitle="IPMS - Request account unlock" />}></Route>

      <Route path="/unlockaccount/:token/"
        element={<UnlockAccount type={"unlock"}
          pageTitle="IPMS - Unlock account" />}></Route>

      <Route path="/m/unlockaccount/:token/"
        element={<UnlockAccount type={"unlock"}
          pageTitle="IPMS - Unlock account" />}></Route>

      <Route path="/setnewpassword/:token/"
        element={
          <SetPassword pageTitle="IPMS - Set Your Password" />
        }></Route>

      <Route path="/m/setnewpassword/:token/"
        element={
          <SetPassword pageTitle="IPMS - Set Your Password" />
        }></Route>

      <Route path="/register"
        element={
          <Signup pageTitle="IPMS - Register" />
        }></Route>

      <Route path="/m/register"
        element={
          <Signup pageTitle="IPMS - Register" />
        }></Route>

      <Route path="/"
        element={<Home platform="desktop" />}></Route>

      <Route path="/m"
        element={<Home platform="mobile" />}></Route>

      <Route path="*" element={<PageNotFound/>} />

    </Routes>
  </Suspense>;
}

function App() {
  useEffect(() => {
    async function prefetch() {
      for (const item of prefetchPages) {
        await item.preload();
      }
    }
    setTimeout(() => { prefetch(); }, 1000);
  }, []);

  return (
    <BrowserRouter>
      <PageRenderProvider view="client">
        <LoadingContextProvider>
          <AuthProvider>
            <ClientRouter />
          </AuthProvider>
        </LoadingContextProvider>
      </PageRenderProvider>
    </BrowserRouter>
  );
}

export default App;
