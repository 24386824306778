import React from "react";
import PropTypes from "prop-types";

import Footer from "components/common/footer/Footer";
import Header from "components/common/header/Header";

import "./oneColumnTheme.css";

export default function OneColumn(props) {
  return (
    <div className="one-column-container">
      <Header index={props.index} />
      {props.children}
      <Footer />
    </div>
  );
}

OneColumn.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node
};
