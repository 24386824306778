import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { Avatar } from "@mui/material";

const RejectItem = ({ notification }) => {
  const navigate = useNavigate();
  const content = notification.content;
  const prefix = notification.unread ? "unread-" : "";

  return (
    <div className="notification-item-link" onClick={() => {
      navigate(`/product/${content.ip.id}-${content.ip.name}/version/${content.ip.version.name}`);
    }}>
      <Avatar sx={{ bgcolor: 'rgb(225 29 72)' }}>
        <FileDownloadOffIcon />
      </Avatar>
      <div className="notification-wrapper">
        <div className={`${prefix}notification-content`}>
          Your request
          <span className={`${prefix}notification-text`}> #{content.ticket_id} </span>
          to download
          <span className={`${prefix}notification-text`}> {content.package_type} </span>
          for
          <span className={`${prefix}notification-text`}> {content.ip.name}{content.ip.version.name} </span>
          has been rejected.
        </div>
        <div className="notification-item-wrapper">
          <div className={`${prefix}notification-time`}>{notification.created_at}</div>
        </div>
      </div>
    </div>
  );
};

RejectItem.propTypes = {
  notification: PropTypes.object
};

export default RejectItem;
