import { React, lazy, useEffect, useState } from "react";
import PropTypes from "prop-types";
import GenericComponentUI from "pages/generic";
import UnsupportedDevice from "pages/errors/unsupportedDevice";
import CustomerAPI from "api/customers/userApi";
import "assets/css/products.css";

const UI = {
  desktop: lazy(() => import(
    `pages/clients/products/Products.ui.desktop`
  ).catch(() => ({ default: () => <UnsupportedDevice platform="desktop"/> }))),

  mobile: lazy(() => import(
    `pages/clients/products/Products.ui.mobile`
  ).catch(() => ({ default: () => <UnsupportedDevice platform="mobile"/> })))
};

export default function Products(props) {
  const [viewLicense, setViewLicense] = useState(false);
  const [licenseInfo, setLicenseInfo] = useState([]);
  const [foundryName, setFoundryName] = useState();
  const productAPI = new CustomerAPI();
  const prefetch = () => {
    import(/* webpackPrefetch: true */ "../product_details/ProductDetails");
  };

  useEffect(() => {
    prefetch();
    productAPI.getCustomerProducts().then((response) => {
      const foundryList = response?.data?.map((obj) => obj?.technology?.foundry?.name || "");
      const uniqueFoundryList = Array.from(new Set(["", ...foundryList]));
      setFoundryName(uniqueFoundryList);
    });
  }, []);

  const getLicenseInfo = async (id) => {
    productAPI.getCustomerLicense().then((response) => {
      const dataModel = response.data.map((obj) => {
        return {
          ...obj,
          license_type: obj?.license_info?.name || "default",
          ip_name: obj?.ip_info?.name || "default",
          product_id: obj?.ip_info?.id || -1,
          ip_versions: obj?.ip_info?.ip_versions || []
        };
      });
      const licenseData = dataModel.filter((obj) => obj.licensed_ip === id);
      setLicenseInfo(licenseData);
    });
  };

  useEffect(() => {
    document.title = props.pageTitle;
  }, []);

  const uiProps = {
    viewLicense,
    setViewLicense,
    getLicenseInfo,
    licenseInfo,
    setLicenseInfo,
    foundryName
  };

  return (
    <GenericComponentUI namespace={UI} platform={props.platform} uiProps={uiProps} />
  );
}

Products.propTypes = {
  pageTitle: PropTypes.string,
  platform: PropTypes.oneOf(["desktop", "mobile"])
};
