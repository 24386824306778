import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import "./baseDialog.css";

const PaperComponent = (props) => {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      bounds="body"
      handle='.dialogTitle'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper sx={{ boxShadow: "gray 0px 0px 20px" }} ref={nodeRef} {...props}/>
    </Draggable>
  );
};

const DialogLoading = ({ loadingMessage }) => {
  loadingMessage = loadingMessage || "Please wait...";
  return (
    <div className='dialogOverlayClass'>
      <div className="dialogLoadingPanel">
        <CircularProgress size={40} style={{ color: "white" }}/>
        <span className='dialogLoadingText'>{loadingMessage}</span>
      </div>
    </div>
  );
};

DialogLoading.propTypes = {
  loadingMessage: PropTypes.string
};

const iconCloseBtnStyle = {
  position: 'absolute',
  right: 4,
  fontSize: 12,
  top: 0,
  color: (theme) => theme.palette.grey[500]
};

const iconShowFullScreenBtnStyle = {
  position: 'absolute',
  right: 25,
  fontSize: 12,
  top: 0,
  color: (theme) => theme.palette.grey[500]
};

const BaseDialog = ({ isOpened, onClosed, title, children, actions, maxWidth, loading, noPadding }) => {
  const [showFullscreen, setShowFullScreen] = useState(false);
  maxWidth = maxWidth || "xs";
  if (!isOpened) return (<></>);
  return (
    <Dialog
      open={isOpened}
      hideBackdrop={true}
      PaperComponent={PaperComponent}
      fullWidth
      maxWidth={maxWidth}
      fullScreen={showFullscreen}>
      {loading?.showLoading && <DialogLoading loadingMessage={loading?.loadingMessage} />}
      <DialogTitle className="dialogTitle">{title}
        <IconButton
          aria-label="showFullScreen"
          sx={iconShowFullScreenBtnStyle}
          onTouchEnd={e => setShowFullScreen(!showFullscreen)}
          onClick={e => setShowFullScreen(!showFullscreen)}>
          {showFullscreen
            ? <ZoomInMapIcon color="action" fontSize='small' />
            : <ZoomOutMapIcon color="action" fontSize='small' />
          }
        </IconButton>
        <IconButton
          aria-label="close"
          sx={iconCloseBtnStyle}
          onTouchEnd={e => onClosed(e)}
          onClick={e => onClosed(e)}>
          <CloseIcon color="action" fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent style={noPadding ? { padding: "0px", overflow: "hidden" } : { paddingTop: "6px" }}>
        {children}
      </DialogContent>
      <DialogActions className='dialogAction'>
        {actions}
      </DialogActions>
    </Dialog>
  );
};

BaseDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClosed: PropTypes.func,
  title: PropTypes.any,
  children: PropTypes.any,
  actions: PropTypes.any,
  maxWidth: PropTypes.string,
  loading: PropTypes.shape({
    loadingMessage: PropTypes.string,
    showLoading: PropTypes.bool
  }),
  noPadding: PropTypes.bool
};

export default memo(BaseDialog);
