import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import CopyrightIcon from '@mui/icons-material/Copyright';
import { Avatar } from "@mui/material";

const LicenseItem = ({ notification }) => {
  const navigate = useNavigate();
  const content = notification.content;
  const prefix = notification.unread ? "unread-" : "";

  return (
    <div className="notification-item-link" onClick={() => {
      navigate(`/product/${content.ip_id}-${content.ip_name}`);
    }}>
      <Avatar sx={{ bgcolor: 'rgb(37 99 235)' }}>
        <CopyrightIcon />
      </Avatar>
      <div className="notification-wrapper">
        <div className={`${prefix}notification-content`}>
          You&apos;ve granted a license for
          <span className={`${prefix}notification-text`}> {content.ip_name}</span>
          . You now have access to the product&apos;s documents.
        </div>
        <div className="notification-item-wrapper">
          <div className={`${prefix}notification-time`}>{notification.created_at}</div>
        </div>
      </div>
    </div>
  );
};

LicenseItem.propTypes = {
  notification: PropTypes.object
};

export default LicenseItem;
